<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            ref="list"
            :expandRowByClick="true"
            @expandedRowsChange="handleExpandedRowsChange($event)"
			:slot_table_list="['status']"
            :expandedRowRender="true"
        >
            <template slot="expandedRowRender" slot-scope="data">
                <a-table
                    :columns="columns_sub"
                    :pagination="false"
                    :dataSource="data.record.list"
                    style="margin: 0"
                ></a-table>
            </template>
            <template slot="status" slot-scope="data">
                <span v-for="item in status_list" :key="item.key" v-show="data.text==item.key">{{item.value}}</span>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";

import { stockOrderWarn, stockWarnGoods } from "@/api/order";


const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "预警客户",
        dataIndex: "customer",
    },
    {
        title: "地址",
        dataIndex: "address",
    },
    {
        title: "上报人",
        dataIndex: "username",
    },
    {
        title: "预警日期",
        dataIndex: "warn_time",
    },
    {
        title: "预警商品",
        dataIndex: "num",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
];

const columns_sub = [
    {
        title: "预警商品",
        dataIndex: "goods_title",
    },
    {
        title: "价格",
        dataIndex: "goods_price",
    }
];
// {
//         key: "",
//         value: "全部"
//     },

let status_list = [
    {
        key: "0",
        value: "待处理"
    },
    {
        key: "1",
        value: "已处理"
    },
    {
        key: "-1",
        value: "忽略"
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
			get_table_list: stockOrderWarn,
			status_list,
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            columns,
            columns_sub,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "customer",
                        title: "客户",
                        placeholder: '客户名称/ID/地址',
                        options: {
                        }
                    },
                    {
                        type: "text",
                        name: "staff",
                        title: "上报人",
                        placeholder: '姓名/账号/工号/手机号',
                        options: {},
					},
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                    {
                        type: "select",
                        name: "status",
                        title: "状态",
                        mode: "default",
                        options: {},
                        list: status_list
					},
					
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },

    methods: {
        async handleExpandedRowsChange(e) {
            let table_list = this.$refs.list.list;
            e.forEach(item => {
                if (!table_list[item].list) {
                    stockWarnGoods({
                        data: {
                            id: table_list[item].id,
                        }
                    }).then(res => {
                        let list = [];
                        table_list.forEach((vo, index) => {
                            if (index == item) {
                                vo.list = res.data.list;
                                vo.goods = res.data.list;
                            }
                            list.push(vo)
                        })
                        this.$refs.list.set_data('list', list)
                    })
                }
            })
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>